import Vue from "vue";
import _ from "lodash";
import "@/lib/polyfills";
import "../../sass/vendor.scss";
import "../../sass/core.scss";

import Vuex from "vuex";
import pathify from "@/store/pathify";
import user from "@/store/modules/user";
import translated from "@/store/modules/translated";
import location from "@/store/modules/location";
import topdeals from "@/store/modules/topdeals";
import campaign from "@/store/modules/campaign";
import sessionReset from "@/store/modules/sessionReset";
import used from "@/store/modules/used";
import vehicleSearch from "@/store/modules/vehicleSearch";
import headerTransitionStore from "@/store/modules/headerTransitionStore";
import CarSaverPlugin from "@/lib/CarSaverPlugin";
import PickPriceDrive from "@/components/PickPriceDrive";
import UsedListings from "Modules/UsedListings";
import SignInRequiredModal from "Components/SignInRequiredModal";
import PrivacyProtectionModal from "Components/PrivacyProtectionModal";
import MobileFilters from "Modules/UsedListings/components/MobileFilters";
import ListingsDealerStickyFooter from "Modules/UsedListings/components/DealerStickyFooter";
import WhatToLookForModal from "Components/WhatToLookForModal";
import VueGeoLocation from "vue-browser-geolocation";
import GeoLocation from "Components/GeoLocation";
import VueI18n from "vue-i18n";
const environment = _.get(window, "appConfig.env[0]", "local") || "local";

Vue.use(Vuex);
Vue.use(VueI18n);
Vue.use(VueGeoLocation);

const debug = environment !== "production";

const store = new Vuex.Store({
    plugins: [pathify.plugin],

    modules: {
        user,
        translated,
        location,
        topdeals,
        sessionReset,
        campaign,
        used,
        vehicleSearch,
        headerTransitionStore
    },
    strict: debug
});

Vue.use(CarSaverPlugin);

const defaultMessages = _.get(store, "state.translated.text", {});
const messages = {
    default: defaultMessages,
    en: {},
    es: {}
};
const i18n = new VueI18n({
    locale: "default",
    fallbackLocale: "default",
    messages
});

if (document.getElementById("root") != null) {
    // eslint-disable-next-line no-new
    new Vue({
        el: "#root",

        store,

        i18n,

        components: {
            PickPriceDrive,
            UsedListings,
            MobileFilters,
            SignInRequiredModal,
            PrivacyProtectionModal,
            ListingsDealerStickyFooter,
            WhatToLookForModal,
            GeoLocation
        }
    });
}
