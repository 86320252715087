<template>
    <div class="ribbon d-flex justify-content-center align-items-center">
        <span>Lifetime Warranty*</span>
    </div>
</template>
<script>
export default {
    name: "LifetimeWarrantyRibbon"
};
</script>
<style lang="scss" scoped>
.ribbon {
    position: absolute;
    top: 10px;
    left: 5px;
    z-index: 2;
    color: #fff;
    min-width: 100px;
    max-width: 180px;
    height: 26px;
    line-height: 26px;
    font-size: 10px;
    font-weight: 700;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    padding-left: 10px;
    padding-right: 5px;
    background-color: $primary-blue;
    letter-spacing: 0.5px;
}

.ribbon:before {
    content: "";
    display: block;
    position: absolute;
    top: 26px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 7px 0;
    border-color: transparent $primary-dark-blue transparent transparent;
}

@include media-breakpoint-down(sm) {
    .ribbon {
        top: 10px;
        left: 15px;
        padding-left: 15px;
    }
    .ribbon:before {
        display: none;
    }
}

@include media-breakpoint-down(sm) {
    .ribbon {
        left: 0px;
    }
}
</style>
