<template>
    <div class="view-selector d-flex justify-content-between">
        <div
            class="list-view-btn"
            :class="{ selected: isListViewSelected }"
            @click="activateListView"
        />
        <div
            class="grid-view-btn"
            :class="{ selected: isGridViewSelected }"
            @click="activateGridView"
        />
    </div>
</template>
<script>
import { commit, get } from "vuex-pathify";

export default {
    name: "ViewSelector",

    computed: {
        isListViewSelected: get("vehicleSearch/listViewActive"),
        isGridViewSelected: get("vehicleSearch/gridViewActive")
    },

    methods: {
        activateListView() {
            commit("vehicleSearch/SET_LIST_VIEW_ACTIVE", true);
        },
        activateGridView() {
            commit("vehicleSearch/SET_GRID_VIEW_ACTIVE", true);
        }
    }
};
</script>
<style lang="scss" scoped>
.view-selector {
    width: 42px;
    color: $dark-gray;

    .list-view-btn,
    .grid-view-btn {
        font-size: 18px;
        font-family: Ionicons;
    }

    *:not(.selected) {
        cursor: pointer;
    }

    .list-view-btn:after {
        content: "\f130";
    }

    .grid-view-btn:after {
        content: "\f13f";
    }

    .selected:after {
        color: $primary-blue;
    }
}
</style>
