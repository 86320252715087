<template>
    <div class="d-flex flex-row list-view position-relative">
        <div v-if="warrantyEligible" class="warranty-ribbon ">
            <lifetime-warranty-ribbon />
        </div>

        <div
            class="listing-image-container align-items-center d-flex flex-column pr-4"
            @click="hotSpotHandler"
        >
            <fixed-vehicle-image
                :image-url="vehicle.imageUrl"
                :backup-image-url="vehicle.genericImageUrl"
                :body-style="vehicle.bodyStyle"
                class="listing-image"
            />
        </div>

        <div
            class="content-container d-flex flex-column justify-content-between w-100 py-3 pr-4"
        >
            <div class="content d-flex flex-column">
                <div class="d-flex justify-content-between">
                    <div
                        class="left-section d-flex flex-column"
                        @click="hotSpotHandler"
                    >
                        <div class="year-make">
                            <span class="text-capitalize font-weight-bold">
                                {{ stockType }}
                            </span>
                            {{ year }} {{ make }}
                        </div>
                        <div class="model">{{ model }} {{ trim }}</div>
                        <div v-if="vehicle.miles" class="miles">
                            <div>
                                <span class="font-italic">
                                    {{ miles | miles }} Miles
                                </span>
                                <span
                                    v-if="exteriorColor"
                                    class="font-italic ml-3 ext-color"
                                >
                                    {{ exteriorColor }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div
                        class="right-section d-flex text-right justify-content-between"
                    >
                        <div v-if="hasLowestPayment" class="flex-column">
                            <div class="starting-price">
                                <span class="label">
                                    {{ pricing.price.title }}
                                </span>
                                <span class="price">
                                    {{ pricing.price.value | numeral("$0,0") }}
                                </span>
                                <tooltip-disclaimer
                                    v-if="pricing.price"
                                    :title="pricing.price.disclaimer"
                                />
                            </div>
                            <div class="monthly-price-container">
                                <div class="monthly-price-value">
                                    {{ lowestPayment | numeral("$0,0") }}
                                </div>
                                <div
                                    class="monthly-price-footer font-italic text-lowercase"
                                >
                                    {{ lowestPaymentType }} /mo.
                                    <tooltip-disclaimer
                                        v-if="pricing.payment"
                                        :title="pricing.payment.disclaimer"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-else-if="!hasLowestPayment" class="flex-column">
                            <div class="starting-price">
                                <span class="label">
                                    {{ pricing.price.title }}
                                </span>
                                <tooltip-disclaimer
                                    v-if="pricing.price"
                                    :title="pricing.price.disclaimer"
                                />
                            </div>
                            <div class="starting-price-lg">
                                {{ pricing.price.value | numeral("$0,0") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="footer-container d-flex justify-content-between align-items-end"
                @click="hotSpotHandler"
            >
                <div class="vehicle-flags-container d-flex">
                    <span
                        v-for="(flagObj, index) in vehicleFlags"
                        :key="flagObj.label + index"
                        :class="flagObj.className"
                    >
                        {{ flagObj.label }}
                    </span>
                </div>
                <div>
                    <vehicle-location :vehicle="vehicle" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import FixedVehicleImage from "Modules/Vehicle/FixedVehicleImage";
import LifetimeWarrantyRibbon from "../../LifetimeWarrantyRibbon";
import mixin from "../mixin";
import TooltipDisclaimer from "Components/TooltipDisclaimer/index";
import VehicleLocation from "Modules/UsedListings/components/VehicleLocation";

export default {
    name: "ListingCard",
    components: {
        VehicleLocation,
        TooltipDisclaimer,
        FixedVehicleImage,
        LifetimeWarrantyRibbon
    },
    mixins: [mixin],
    props: {
        vehicle: {
            type: Object,
            required: true
        }
    }
};
</script>
<style lang="scss" scoped>
.vehicle-flags-container {
    font-size: px2rem(12);
    font-family: $helvetica-46-light-italic;

    span {
        margin-right: 16px;
        text-transform: capitalize;

        &.blue {
            color: $primary-blue;
        }

        &.orange-bold {
            color: $orange;
            font-weight: bold;
        }

        &.blue-bold {
            color: $primary-blue !important;
            font-weight: bold;
        }
    }
}

.left-section,
.right-section {
    overflow: hidden;
}

.left-section {
    flex: 1;
}

.right-section {
    padding-right: 1px;
}

.cta-container {
    .cta.split:after {
        content: "|";
        margin: 0 10px;
        color: #4a4a4a;
    }
}

.miles {
    font-family: $helvetica-46-light-italic;
    font-size: px2rem(14);
    height: 14px;
}

.starting-price {
    .price {
        font-family: $helvetica-55-Roman;
    }
}

.content-container {
    max-width: calc(100% - 240px);
}

.content {
    font-size: px2rem(12);
    font-weight: normal;
    line-height: px2rem(13);

    .model {
        font-size: px2rem(28);
        font-weight: 300;
        line-height: 34px;
    }

    .monthly-price-value,
    .starting-price-lg {
        font-size: px2rem(28);
        font-weight: 300;
        line-height: px2rem(44);
    }

    .model {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
    }

    .monthly-price-value {
        color: $primary-orange;
    }
}

.monthly-price-container {
    .font-italic {
        font-family: $helvetica-46-light-italic;
    }
}

.listing-image-container {
    min-width: 240px;

    .listing-image {
        width: 100%;
        max-width: 270px;
        /*margin-top: 15px;*/

        .vehicle-image-main {
            /*background-size: contain !important;;*/
            background-repeat: no-repeat !important;
            background-position: center center !important;
        }
    }
}

.warranty-ribbon {
    .ribbon {
        left: -10px;
    }
}

.footer-container {
    flex: 1;
}

.list-view {
    color: $dark-gray;
    height: 145px;
    background-color: $card-background;

    &:hover,
    &:active {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
    }
}

.listing-image-container,
.left-section,
.footer-container {
    &:hover {
        cursor: pointer;
    }
}
</style>
<style lang="scss"></style>
