<template>
    <div class="d-flex flex-column grid-view position-relative">
        <div class="header-container d-flex flex-column w-100">
            <div class="header-top d-flex justify-content-between">
                <div
                    class="left-section d-flex flex-column"
                    @click="hotSpotHandler"
                >
                    <div class="year-make">
                        <span class="text-capitalize font-weight-bold">
                            {{ stockType }}
                        </span>
                        {{ year }} {{ make }}
                    </div>
                    <div class="model">{{ model }} {{ trim }}</div>
                    <div>
                        <span v-if="vehicle.miles" class="px-1">
                            {{ miles | miles }} Miles
                        </span>
                        <span v-if="vehicle.exteriorColorName" class="px-1">
                            {{ vehicle.exteriorColorName }}
                        </span>
                    </div>
                </div>

                <div
                    v-if="hasLowestPayment"
                    class="right-section d-flex flex-column text-right"
                >
                    <div class="starting-price">
                        <span class="font-italic">
                            {{ pricing.price.title }}:
                        </span>
                        <span class="price">
                            {{ pricing.price.value | numeral("$0,0") }}
                        </span>
                        <tooltip-disclaimer
                            v-if="pricing.price"
                            :title="pricing.price.disclaimer"
                        />
                    </div>
                    <div class="monthly-price-container">
                        <div class="monthly-price-value">
                            {{ lowestPayment | numeral("$0,0") }}
                        </div>
                        <div
                            class="monthly-price-footer font-italic text-lowercase"
                        >
                            {{ lowestPaymentType }} /mo.
                            <tooltip-disclaimer
                                v-if="pricing.payment"
                                :title="pricing.payment.disclaimer"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="!hasLowestPayment"
                    class="right-section d-flex flex-column text-right"
                >
                    <div class="starting-price">
                        <span class="font-italic">
                            {{ pricing.price.title }}
                        </span>
                        <tooltip-disclaimer
                            v-if="pricing.price"
                            :title="pricing.price.disclaimer"
                        />
                    </div>
                    <div class="starting-price-lg">
                        {{ pricing.price.value | numeral("$0,0") }}
                    </div>
                </div>
            </div>
        </div>

        <div
            class="listing-image-container align-items-center d-flex flex-column"
        >
            <warranty-ribbon v-if="vehicle.warrantyEligible" />
            <fixed-vehicle-image
                :image-url="vehicle.imageUrl"
                :backup-image-url="vehicle.genericImageUrl"
                class="listing-image"
                :click="hotSpotHandler"
                :body-style="vehicle.bodyStyle"
            />
            <div
                class="vehicle-flags-container d-flex justify-content-center align-items-center"
            >
                <span v-if="certifiedPreowned" class="mr-2 p-2">
                    Certified Pre-Owned
                </span>
            </div>
        </div>

        <div
            class="footer-container py-2 px-0 d-flex align-items-center justify-content-between"
            @click="hotSpotHandler"
        >
            <div class="d-flex flex-wrap flags font-italic">
                <div
                    v-for="(flagObj, index) in vehicleFlags"
                    :key="flagObj.label + index"
                    :class="flagObj.className"
                    class="mr-2 mr-md-3"
                >
                    {{ flagObj.label }}
                </div>
            </div>
            <div class="vehicle-location-wrapper">
                <vehicle-location :vehicle="vehicle" />
            </div>
        </div>
    </div>
</template>
<script>
import FixedVehicleImage from "Modules/Vehicle/FixedVehicleImage";
import mixin from "../mixin";
import WarrantyRibbon from "./WarrantyRibbon";
import TooltipDisclaimer from "Components/TooltipDisclaimer/index";
import VehicleLocation from "Modules/UsedListings/components/VehicleLocation";

export default {
    name: "ListingCard",
    components: {
        VehicleLocation,
        TooltipDisclaimer,
        WarrantyRibbon,
        FixedVehicleImage
    },
    mixins: [mixin],
    props: {
        vehicle: {
            type: Object,
            required: true
        }
    }
};
</script>
<style lang="scss" scoped>
.vehicle-location-wrapper {
    max-width: 50%;
}
.footer-container {
    .flags {
        font-size: px2rem(12);

        .orange-bold {
            color: $orange;
            font-weight: bold;
        }

        .blue-bold {
            color: $primary-blue !important;
            font-weight: bold;
        }
    }
}

i {
    font-size: 11px;
}

.vehicle-flags-container {
    font-size: px2rem(10);
    position: absolute;
    bottom: 10px;
    left: 15px;

    span {
        font-family: $helvetica-55-Roman;
        color: $dark-gray;
        background-color: $card-background;
        border-radius: 2px;
        margin-right: 10px;
        text-transform: capitalize;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
    }
}

.certified-flag,
.warranty-flag {
    color: white;
    line-height: 30px;
    height: 30px;
    font-size: px2rem(10);
    position: absolute;
    top: 95px;
    left: 0px;
    border-radius: 2px;
    z-index: 1;
}
.warranty-flag {
    background-color: $primary-blue;
}

.certified-flag {
    background-color: #a6a6a6;
}

.left-section,
.right-section {
    overflow: hidden;
}

.left-section {
    flex: 1;
}

.right-section {
    padding-right: 1px;
}

.cta-container {
    .cta.split:after {
        content: "|";
        margin: 0 10px;
        color: #4a4a4a;
    }
}

.save-up-to {
    font-size: px2rem(20);
}

.starting-price {
    .price {
        font-family: $helvetica-55-Roman;
        font-size: px2rem(12);
    }
}

.header-container {
    font-size: px2rem(11);
    font-weight: normal;
    line-height: px2rem(13);
    height: 70px;

    .monthly-price-footer {
        font-family: $helvetica-46-light-italic;
    }

    .monthly-price-value,
    .model {
        font-size: px2rem(28);
        font-weight: 300;
        line-height: 34px;
    }

    .starting-price-lg {
        font-size: px2rem(28);
        font-weight: 300;
        line-height: px2rem(44);
    }

    .model {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
        max-width: 260px;
    }

    .monthly-price-value {
        color: $primary-orange;
    }
}

.grid-view {
    color: $dark-gray;
    height: 440px;
    padding: 15px 15px 0;
    background-color: $card-background;

    &:hover,
    &:active {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
    }
}

.listing-image,
.left-section,
.footer-container {
    &:hover {
        cursor: pointer;
    }
}
</style>
<style lang="scss">
.grid-view {
    .listing-image-container {
        flex: 1;
        position: relative;
        margin-left: -15px;
        margin-right: -15px;

        .listing-image {
            width: 100%;

            .vehicle-image-main {
                background-repeat: no-repeat !important;
                background-position: center center !important;
            }
        }
    }
    .footer-container {
        height: 55px;
        .deal-badge {
            font-size: px2rem(12);
        }
    }

    .body-style {
        background-color: #e4e4e4;
    }

    .progressive-vehicle-image .body-style > i {
        font-size: 5.5rem !important;
    }
}
</style>
