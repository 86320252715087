import { get } from "vuex-pathify";
import _ from "lodash";
import URL from "url-parse";
import numeral from "numeral";

export default {
    filters: {
        miles: function(value) {
            if (typeof value !== "number") {
                return null;
            }

            return numeral(value)
                .format("0a")
                .toUpperCase();
        },

        capitalize: function(value) {
            if (typeof value !== "string") {
                return value;
            }

            const words = value.split(" ");
            const capitalized = words
                .map(function(word) {
                    return _.capitalize(word);
                })
                .join(" ");

            return capitalized;
        }
    },

    computed: {
        deliveryEnabled() {
            return _.get(this.vehicle, "dealer.deliveryEnabled");
        },
        deliveryDistance() {
            return _.get(this.vehicle, "dealer.deliveryDistance");
        },
        distanceFromUser() {
            return _.get(this.vehicle, "distanceFromUser", null);
        },
        userIsInsideDeliveryDistance() {
            if (this.distanceFromUser != null) {
                return _.lte(this.distanceFromUser, this.deliveryDistance);
            } else {
                return false;
            }
        },
        biggestSavings() {
            return _.get(this.vehicle, "biggestSavings.value");
        },
        buildAndPriceUrl() {
            return `/shop-by/make/${this.vehicle.make}/model/${this.vehicle.modelId}`;
        },
        certifiedPreowned() {
            return (
                _.get(this.vehicle, "certified") === true &&
                _.get(this.vehicle, "stockType") === "USED"
            );
        },
        isOneOwner() {
            return (
                _.get(this.vehicle, "oneOwner") === true &&
                _.get(this.vehicle, "stockType") === "USED"
            );
        },
        isCertifiedDealer() {
            return _.get(this.vehicle, "dealer.certified", false);
        },
        hasSupplierPricing() {
            return _.get(this.vehicle, "supplierPricingAvailable", false);
        },
        dealBadge() {
            return _.get(this.vehicle, "dealBadge", null);
        },
        exteriorColor: function() {
            let color = _.get(this.vehicle, "exteriorColorName", null);

            if (color) {
                color = color.toLowerCase();
                color = color[0].toUpperCase() + color.slice(1);
            }

            return color;
        },
        hasLowestPayment() {
            return !_.isNil(_.get(this.vehicle, "quote.monthlyPayment", null));
        },
        hasLowestPrice() {
            return !_.isNil(_.get(this.vehicle, "lowestPrice", null));
        },
        hasSavings() {
            const type = this.stockType.toLowerCase();

            if (type === "new") {
                return !_.isNil(this.savings) && this.savings > 1;
            }

            return false;
        },
        internetPrice: function() {
            return _.get(this.vehicle, "internetPrice");
        },
        isBuildandPriceAvailable: function() {
            const monthsBack = 6;
            const now = new Date();

            now.setMonth(now.getMonth() - monthsBack); // rolling back
            const rollBackYear = now.getFullYear();

            return this.vehicle.year >= rollBackYear;
        },

        isListingsAvailable: function() {
            return this.vehicle.count > 0;
        },
        isSignedIn: get("user/isSignedIn"),
        listingsUrl() {
            const url = new URL("/shop/new", true);

            const filters = {
                makes: [this.vehicle.make],
                models: [this.vehicle.model],
                minYear: this.vehicle.year,
                maxYear: this.vehicle.year
            };

            url.query = {
                ...filters
            };

            return url.toString();
        },
        lowestPayment() {
            return _.get(this.vehicle, "quote.monthlyPayment");
        },
        lowestPaymentType() {
            const paymentType = _.get(this.vehicle, "quote.type", "");
            switch (_.toUpper(paymentType)) {
                case "LOAN":
                    return "Finance";
                case "CLOSED END":
                    return "Lease";
                default:
                    return paymentType;
            }
        },
        make: function() {
            return _.get(this.vehicle, "make");
        },
        miles: function() {
            return _.get(this.vehicle, "miles", 0);
        },
        model: function() {
            return _.get(this.vehicle, "model");
        },
        msrp: function() {
            return _.get(this.vehicle, "msrp");
        },
        paymentDisclaimer() {
            return _.get(this.vehicle, "quote.disclaimer");
        },
        pricing: function() {
            let listingObj = {};
            const defaults = {
                price: {
                    title: null,
                    value: null,
                    disclaimer: null
                },
                payment: {
                    type: this.lowestPaymentType,
                    value: this.lowestPayment,
                    disclaimer: this.paymentDisclaimer
                },
                savings: {
                    value: this.savings,
                    disclaimer: this.savingsDisclaimer
                }
            };

            switch (this.stockType) {
                case "new":
                    listingObj = {
                        ...defaults,
                        price: {
                            title: "MSRP",
                            value: this.msrp,
                            disclaimer: this.$t("disclaimer.msrp")
                        }
                    };
                    break;
                case "used":
                    listingObj = {
                        ...defaults,
                        price: {
                            title: "Retail Price",
                            value: this.internetPrice,
                            disclaimer: this.$t("disclaimer.retailPrice")
                        }
                    };
                    break;
                default:
                    break;
            }

            return listingObj;
        },
        savings() {
            return _.get(this.vehicle, "savings");
        },
        savingsDisclaimer: function() {
            return _.get(this.vehicle, "savingsDisclaimer");
        },
        showBuildandPrice() {
            return this.isBuildandPriceAvailable;
        },
        stockType: function() {
            let type = _.get(this.vehicle, "stockType");

            if (!_.isNil(type)) {
                type = type.toLowerCase();
            }

            return type;
        },
        title() {
            return `${this.vehicle.year} ${this.vehicle.make} ${this.vehicle.model}`;
        },
        trim: function() {
            return _.get(this.vehicle, "trim", "");
        },
        vehicleCount: function() {
            return _.get(this.vehicle, "count");
        },
        vehicleFlags: function() {
            const flags = [
                this.isCertifiedDealer
                    ? {
                          label: this.$t(
                              "component.usedListings.carsaverCertifedDealer"
                          ),
                          className: "font-italic orange-bold"
                      }
                    : null,
                this.deliveryEnabled && this.userIsInsideDeliveryDistance
                    ? {
                          label: this.$t(
                              "component.usedListings.dealerDelivery"
                          ),
                          className: "font-italic blue-bold"
                      }
                    : null,
                this.hasSupplierPricing
                    ? {
                          label: this.$t(
                              "component.usedListings.supplierPricingAvailable"
                          ),
                          className: "font-italic"
                      }
                    : null,
                this.certifiedPreowned
                    ? {
                          label: this.$t(
                              "component.usedListings.certifiedPreOwned"
                          ),
                          className: "font-italic"
                      }
                    : null,
                this.isOneOwner
                    ? { label: "1 Owner", className: "font-italic" }
                    : null
            ];

            return flags.filter(flag => flag !== null && flag !== "");
        },
        warrantyEligible: function() {
            return _.get(this.vehicle, "warrantyEligible", false);
        },
        year: function() {
            return _.get(this.vehicle, "year");
        }
    },
    methods: {
        gotoVehicleDetails() {
            window.location = `/shop/${this.vehicle.stockType.toLowerCase()}/${
                this.vehicle.id
            }`;
        },

        showListings() {
            const url = new URL("/shop/new", true);

            const filters = {
                makes: [this.vehicle.make],
                models: [this.vehicle.model],
                minYear: this.vehicle.year,
                maxYear: this.vehicle.year
            };

            url.query = {
                ...filters
            };

            window.location.href = url.toString();
        },

        goToBuildAndPrice() {
            window.location.href = this.buildAndPriceUrl;
        },

        hotSpotHandler() {
            this.gotoVehicleDetails();
        }
    }
};
