var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-row list-view position-relative" },
    [
      _vm.warrantyEligible
        ? _c(
            "div",
            { staticClass: "warranty-ribbon" },
            [_c("lifetime-warranty-ribbon")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "listing-image-container align-items-center d-flex flex-column pr-4",
          on: { click: _vm.hotSpotHandler }
        },
        [
          _c("fixed-vehicle-image", {
            staticClass: "listing-image",
            attrs: {
              "image-url": _vm.vehicle.imageUrl,
              "backup-image-url": _vm.vehicle.genericImageUrl,
              "body-style": _vm.vehicle.bodyStyle
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-container d-flex flex-column justify-content-between w-100 py-3 pr-4"
        },
        [
          _c("div", { staticClass: "content d-flex flex-column" }, [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c(
                "div",
                {
                  staticClass: "left-section d-flex flex-column",
                  on: { click: _vm.hotSpotHandler }
                },
                [
                  _c("div", { staticClass: "year-make" }, [
                    _c(
                      "span",
                      { staticClass: "text-capitalize font-weight-bold" },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.stockType) +
                            "\n                        "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.year) +
                        " " +
                        _vm._s(_vm.make) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "model" }, [
                    _vm._v(_vm._s(_vm.model) + " " + _vm._s(_vm.trim))
                  ]),
                  _vm._v(" "),
                  _vm.vehicle.miles
                    ? _c("div", { staticClass: "miles" }, [
                        _c("div", [
                          _c("span", { staticClass: "font-italic" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm._f("miles")(_vm.miles)) +
                                " Miles\n                            "
                            )
                          ]),
                          _vm._v(" "),
                          _vm.exteriorColor
                            ? _c(
                                "span",
                                { staticClass: "font-italic ml-3 ext-color" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.exteriorColor) +
                                      "\n                            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "right-section d-flex text-right justify-content-between"
                },
                [
                  _vm.hasLowestPayment
                    ? _c("div", { staticClass: "flex-column" }, [
                        _c(
                          "div",
                          { staticClass: "starting-price" },
                          [
                            _c("span", { staticClass: "label" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.pricing.price.title) +
                                  "\n                            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "price" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm._f("numeral")(
                                      _vm.pricing.price.value,
                                      "$0,0"
                                    )
                                  ) +
                                  "\n                            "
                              )
                            ]),
                            _vm._v(" "),
                            _vm.pricing.price
                              ? _c("tooltip-disclaimer", {
                                  attrs: { title: _vm.pricing.price.disclaimer }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "monthly-price-container" }, [
                          _c("div", { staticClass: "monthly-price-value" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm._f("numeral")(_vm.lowestPayment, "$0,0")
                                ) +
                                "\n                            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "monthly-price-footer font-italic text-lowercase"
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.lowestPaymentType) +
                                  " /mo.\n                                "
                              ),
                              _vm.pricing.payment
                                ? _c("tooltip-disclaimer", {
                                    attrs: {
                                      title: _vm.pricing.payment.disclaimer
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ])
                    : !_vm.hasLowestPayment
                    ? _c("div", { staticClass: "flex-column" }, [
                        _c(
                          "div",
                          { staticClass: "starting-price" },
                          [
                            _c("span", { staticClass: "label" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.pricing.price.title) +
                                  "\n                            "
                              )
                            ]),
                            _vm._v(" "),
                            _vm.pricing.price
                              ? _c("tooltip-disclaimer", {
                                  attrs: { title: _vm.pricing.price.disclaimer }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "starting-price-lg" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("numeral")(
                                  _vm.pricing.price.value,
                                  "$0,0"
                                )
                              ) +
                              "\n                        "
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "footer-container d-flex justify-content-between align-items-end",
              on: { click: _vm.hotSpotHandler }
            },
            [
              _c(
                "div",
                { staticClass: "vehicle-flags-container d-flex" },
                _vm._l(_vm.vehicleFlags, function(flagObj, index) {
                  return _c(
                    "span",
                    { key: flagObj.label + index, class: flagObj.className },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(flagObj.label) +
                          "\n                "
                      )
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                [_c("vehicle-location", { attrs: { vehicle: _vm.vehicle } })],
                1
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }