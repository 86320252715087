var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    { staticClass: "mx-0 my-2 p-0", attrs: { id: "listingIndicatorMobile" } },
    [
      _c("div", { staticClass: "warranty-indicator" }, [
        _c("span", [_vm._v("Vehicle comes with")]),
        _vm._v(" "),
        _c("span", { staticClass: "font-weight-bold" }, [
          _vm._v("Lifetime Warranty at no cost to you.")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "certified-dealer-indicator" }, [
        _c("span", [
          _vm._v("Located at a\n            "),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v("CarSaver Certified Dealership.")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "deal-rating-indicator" }, [
        _c("span", [_vm._v("Manufacturer offers")]),
        _vm._v(" "),
        _c("span", { staticClass: "font-weight-bold" }, [
          _vm._v("special pricing for Walmart associates.")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }