var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "used-listings" } },
    [
      _c(
        "b-container",
        { staticClass: "layout mt-md-0 p-0", attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "no-gutters-sm-down" },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-none d-md-block left-section",
                  attrs: { md: "3" }
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("desktop-filters", {
                            attrs: {
                              "is-loading": _vm.isLoading,
                              "show-payment-type-facet":
                                _vm.showPaymentTypeFacet,
                              "show-stock-type-facet": _vm.showStockTypeFacet,
                              "show-miles-facet": _vm.showMilesFacet,
                              "is-dealer-inventory-search":
                                _vm.isDealerInventorySearch
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "right-section", attrs: { sm: "12", md: "9" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-none d-md-flex no-gutters-sm-down justify-content-between flex-wrap align-items-center mb-3"
                    },
                    [
                      !_vm.showMilesFacet
                        ? _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex sorting view-selection" },
                              [
                                _c(
                                  "b-link",
                                  {
                                    staticClass: "px-2 inactive-view",
                                    on: { click: _vm.showModels }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Models\n                            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "px-0" }, [
                                  _vm._v(
                                    "\n                                |\n                            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "px-2 active-view" },
                                  [
                                    _vm._v(
                                      "\n                                Listings\n                            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.vehicles && _vm.vehicles.length > 0
                        ? _c("div", { staticClass: "font-italic total" }, [
                            !_vm.isTypeUsed
                              ? _c("span", [
                                  _vm._v(
                                    "\n                            (" +
                                      _vm._s(
                                        _vm._f("numeral")(_vm.total, "0,0")
                                      ) +
                                      " matches)\n                        "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("numeral")(_vm.total, "0,0")
                                      ) +
                                      " matches\n                        "
                                  )
                                ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "sorting d-flex text-nowrap sort-wrapper justify-content-end align-items-center"
                        },
                        [
                          _c("sort-selector", {
                            attrs: {
                              "is-dealer-inventory-search":
                                _vm.isDealerInventorySearch
                            }
                          }),
                          _vm._v(" "),
                          _c("view-selector", { staticClass: "ml-3" })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    { staticClass: "mb-3 no-gutters-sm-down" },
                    [
                      _c(
                        "b-col",
                        [
                          _vm.isLoading
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column loading-wrapper justify-content-center align-items-center py-5"
                                },
                                [
                                  _c("loading-spinner", {
                                    attrs: { size: 60 }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "pt-3" }, [
                                    _vm._v(
                                      "\n                                Loading Inventory ...\n                            "
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isLoading && !_vm.isError && _vm.showGridView
                            ? _c(
                                "div",
                                { staticClass: "px-3 px-md-0" },
                                [
                                  _c("inventory-mobile-navmenu", {
                                    staticClass: "d-md-none"
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.certifiedDealer, function(
                                    innerVehicles,
                                    distanceBucket
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: distanceBucket,
                                        staticClass:
                                          "listings d-flex flex-wrap justify-content-between"
                                      },
                                      [
                                        !_vm.distanceFirst(distanceBucket)
                                          ? _c("distance-bucket-label", {
                                              attrs: {
                                                distance: distanceBucket
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm._l(innerVehicles, function(
                                          vehicle
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: vehicle.id,
                                              staticClass: "grid-listing"
                                            },
                                            [
                                              _c("grid-view", {
                                                attrs: { vehicle: vehicle }
                                              })
                                            ],
                                            1
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  }),
                                  _vm._v(" "),
                                  _vm.nonCertifiedDealer.length !== 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "standard-listing-title w-100"
                                        },
                                        [
                                          _c("p", { staticClass: "mb-2" }, [
                                            _vm._v(
                                              "\n                                    Standard Listings\n                                "
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.nonCertifiedDealer, function(
                                    vehicle
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: vehicle.id,
                                        staticClass: "grid-listing"
                                      },
                                      [
                                        _c("grid-view", {
                                          attrs: { vehicle: vehicle }
                                        })
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isLoading && !_vm.isError && !_vm.showGridView
                            ? _c(
                                "div",
                                [
                                  _vm._l(_vm.certifiedDealer, function(
                                    innerVehicles,
                                    distanceBucket
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: distanceBucket,
                                        staticClass:
                                          "listings d-flex flex-column"
                                      },
                                      [
                                        !_vm.distanceFirst(distanceBucket)
                                          ? _c("distance-bucket-label", {
                                              attrs: {
                                                distance: _vm.mapToDistance(
                                                  distanceBucket
                                                )
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm._l(innerVehicles, function(
                                          vehicle
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: vehicle.id,
                                              staticClass: "listing"
                                            },
                                            [
                                              _c("listing-card", {
                                                attrs: { vehicle: vehicle }
                                              })
                                            ],
                                            1
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  }),
                                  _vm._v(" "),
                                  _vm.nonCertifiedDealer.length !== 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "standard-listing-title w-100"
                                        },
                                        [
                                          _c("p", { staticClass: "mb-2" }, [
                                            _vm._v(
                                              "\n                                    Standard Listings\n                                "
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.nonCertifiedDealer, function(
                                    vehicle
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: vehicle.id,
                                        staticClass: "listing"
                                      },
                                      [
                                        _c("listing-card", {
                                          attrs: { vehicle: vehicle }
                                        })
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isLoading &&
                          (_vm.isCollectionEmpty(_vm.vehicles) || _vm.isError)
                            ? _c(
                                "b-row",
                                { staticClass: "no-gutters-sm-down" },
                                [
                                  _c(
                                    "b-col",
                                    { staticClass: "text-center pt-5" },
                                    [
                                      _c("h1", [_vm._v("No Vehicles Found")]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "\n                                    Sorry, no results matched your search\n                                    criteria.\n                                "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _vm.distance !== -1
                                        ? _c("div", [
                                            _c("p", [
                                              _vm._v(
                                                "\n                                        Would you like to expand your search\n                                        distance?\n                                    "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-center align-items-center"
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant: "primary"
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.expandSearchDistance
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            Expand Search Distance\n                                        "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ])
                                        : _c("div", [
                                            _c("p", [
                                              _vm._v(
                                                "\n                                        Would you like to clear all filters\n                                        to start again?\n                                    "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-center align-items-center"
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant: "primary"
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.clearAllSelectedFilters
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "component.facets.clearAll"
                                                          )
                                                        ) +
                                                        "\n                                        "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isLoading && !_vm.isCollectionEmpty(_vm.vehicles)
                    ? _c(
                        "b-row",
                        { staticClass: "mb-3 no-gutters-sm-down" },
                        [
                          _c(
                            "b-col",
                            [
                              _c("b-pagination", {
                                attrs: {
                                  "total-rows": _vm.paginationTotal,
                                  "per-page": _vm.perPage,
                                  align: "center"
                                },
                                on: { input: _vm.pageChange },
                                model: {
                                  value: _vm.page,
                                  callback: function($$v) {
                                    _vm.page = $$v
                                  },
                                  expression: "page"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "no-gutters-sm-down" },
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "warranty-disclaimer p-3 px-md-0" },
                  [_c("lifetime-warranty-disclaimer")],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("geo-location"),
      _vm._v(" "),
      _c("sign-in-required-modal"),
      _vm._v(" "),
      _c("privacy-protection-modal"),
      _vm._v(" "),
      _c(
        "what-to-look-for-modal",
        [
          _c(
            "template",
            { slot: "indicator" },
            [
              _vm.isListViewSelected
                ? _c("linear-indicator", { staticClass: "d-none d-md-flex" })
                : _vm._e(),
              _vm._v(" "),
              _vm.isGridViewSelected
                ? _c("grid-indicator", { staticClass: "d-none d-md-flex" })
                : _vm._e(),
              _vm._v(" "),
              _c("listing-indicator-mobile", {
                staticClass: "d-flex d-md-none"
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("template", { slot: "checkbox" }, [
            _c("div", { staticClass: "text-center" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.listingIndicatorChecked,
                    expression: "listingIndicatorChecked"
                  }
                ],
                staticClass: "mr-1",
                attrs: { id: "checkbox1", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.listingIndicatorChecked)
                    ? _vm._i(_vm.listingIndicatorChecked, null) > -1
                    : _vm.listingIndicatorChecked
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.listingIndicatorChecked,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          (_vm.listingIndicatorChecked = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.listingIndicatorChecked = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.listingIndicatorChecked = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "checkbox1" } }, [
                _vm._v("Got It. Do not show this listings message again.")
              ])
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }