<template>
    <div class="distance-bucket-label w-100">
        Greater than {{ distance }} miles
    </div>
</template>

<style lang="scss">
.distance-bucket-label {
    text-align: center;
    padding: 15px 0 0;
    border-bottom: solid 1px #ccc;
    margin-bottom: 10px;
}
</style>

<script>
export default {
    props: {
        distance: {
            type: Number,
            required: true
        }
    }
};
</script>
