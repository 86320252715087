<template>
    <div class="vehicle-location text-right">
        <span v-if="vehicle.dealer.deliveryOnly"
            >Delivers to
            <span class="text-nowrap"
                >{{ location.city }}, {{ location.state }}</span
            ></span
        >
        <span v-else
            >Near
            <span class="text-nowrap"
                >{{ vehicle.dealer.city }}, {{ vehicle.dealer.stateCode }}</span
            ></span
        >
    </div>
</template>

<style lang="scss" scoped>
.vehicle-location {
    font-size: px2rem(12);
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>

<script>
import { get } from "vuex-pathify";

export default {
    props: {
        vehicle: {
            type: Object,
            required: true
        }
    },
    computed: {
        location: get("location/selectedLocation")
    }
};
</script>
