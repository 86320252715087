var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-selector d-flex justify-content-between" },
    [
      _c("div", {
        staticClass: "list-view-btn",
        class: { selected: _vm.isListViewSelected },
        on: { click: _vm.activateListView }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "grid-view-btn",
        class: { selected: _vm.isGridViewSelected },
        on: { click: _vm.activateGridView }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }