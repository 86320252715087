var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "ribbon d-flex justify-content-center align-items-center"
      },
      [_c("span", [_vm._v("Lifetime Warranty*")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }