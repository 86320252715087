<template>
    <div id="used-listings">
        <b-container fluid class="layout mt-md-0 p-0">
            <b-row class="no-gutters-sm-down">
                <b-col class="d-none d-md-block left-section" md="3">
                    <b-row>
                        <b-col>
                            <desktop-filters
                                :is-loading="isLoading"
                                :show-payment-type-facet="showPaymentTypeFacet"
                                :show-stock-type-facet="showStockTypeFacet"
                                :show-miles-facet="showMilesFacet"
                                :is-dealer-inventory-search="
                                    isDealerInventorySearch
                                "
                            />
                        </b-col>
                    </b-row>
                </b-col>

                <b-col class="right-section" sm="12" md="9">
                    <div
                        class="d-none d-md-flex no-gutters-sm-down justify-content-between flex-wrap align-items-center mb-3"
                    >
                        <div v-if="!showMilesFacet" class="d-flex">
                            <div class="d-flex sorting view-selection">
                                <b-link
                                    class="px-2 inactive-view"
                                    @click="showModels"
                                >
                                    Models
                                </b-link>
                                <span class="px-0">
                                    |
                                </span>
                                <span class="px-2 active-view">
                                    Listings
                                </span>
                            </div>
                        </div>
                        <div
                            v-if="vehicles && vehicles.length > 0"
                            class="font-italic total"
                        >
                            <span v-if="!isTypeUsed">
                                ({{ total | numeral("0,0") }} matches)
                            </span>
                            <span v-else>
                                {{ total | numeral("0,0") }} matches
                            </span>
                        </div>
                        <div
                            class="sorting d-flex text-nowrap sort-wrapper justify-content-end align-items-center"
                        >
                            <sort-selector
                                :is-dealer-inventory-search="
                                    isDealerInventorySearch
                                "
                            />
                            <view-selector class="ml-3" />
                        </div>
                    </div>

                    <b-row class="mb-3 no-gutters-sm-down">
                        <b-col>
                            <div
                                v-if="isLoading"
                                class="d-flex flex-column loading-wrapper justify-content-center align-items-center py-5"
                            >
                                <loading-spinner :size="60" />
                                <span class="pt-3">
                                    Loading Inventory ...
                                </span>
                            </div>
                            <!-- eslint-disable vue/no-use-v-if-with-v-for -->

                            <!-- Grid View -->
                            <div
                                v-if="!isLoading && !isError && showGridView"
                                class="px-3 px-md-0"
                            >
                                <inventory-mobile-navmenu class="d-md-none" />

                                <!-- Certified-->
                                <div
                                    v-for="(innerVehicles,
                                    distanceBucket) in certifiedDealer"
                                    :key="distanceBucket"
                                    class="listings d-flex flex-wrap justify-content-between"
                                >
                                    <distance-bucket-label
                                        v-if="!distanceFirst(distanceBucket)"
                                        :distance="distanceBucket"
                                    />

                                    <div
                                        v-for="vehicle in innerVehicles"
                                        :key="vehicle.id"
                                        class="grid-listing"
                                    >
                                        <grid-view :vehicle="vehicle" />
                                    </div>
                                </div>

                                <!-- Non Certified -->
                                <div
                                    v-if="nonCertifiedDealer.length !== 0"
                                    class="standard-listing-title w-100"
                                >
                                    <p class="mb-2">
                                        Standard Listings
                                    </p>
                                </div>
                                <div
                                    v-for="vehicle in nonCertifiedDealer"
                                    :key="vehicle.id"
                                    class="grid-listing"
                                >
                                    <grid-view :vehicle="vehicle" />
                                </div>
                            </div>

                            <!-- Listing View -->
                            <div v-if="!isLoading && !isError && !showGridView">
                                <!-- Certified-->
                                <div
                                    v-for="(innerVehicles,
                                    distanceBucket) in certifiedDealer"
                                    :key="distanceBucket"
                                    class="listings d-flex flex-column"
                                >
                                    <distance-bucket-label
                                        v-if="!distanceFirst(distanceBucket)"
                                        :distance="
                                            mapToDistance(distanceBucket)
                                        "
                                    />

                                    <div
                                        v-for="vehicle in innerVehicles"
                                        :key="vehicle.id"
                                        class="listing"
                                    >
                                        <listing-card :vehicle="vehicle" />
                                    </div>
                                </div>

                                <!-- Non Certified -->
                                <div
                                    v-if="nonCertifiedDealer.length !== 0"
                                    class="standard-listing-title w-100"
                                >
                                    <p class="mb-2">
                                        Standard Listings
                                    </p>
                                </div>
                                <div
                                    v-for="vehicle in nonCertifiedDealer"
                                    :key="vehicle.id"
                                    class="listing"
                                >
                                    <listing-card :vehicle="vehicle" />
                                </div>
                            </div>

                            <b-row
                                v-if="
                                    !isLoading &&
                                        (isCollectionEmpty(vehicles) || isError)
                                "
                                class="no-gutters-sm-down"
                            >
                                <b-col class="text-center pt-5">
                                    <h1>No Vehicles Found</h1>
                                    <p>
                                        Sorry, no results matched your search
                                        criteria.
                                    </p>

                                    <div v-if="distance !== -1">
                                        <p>
                                            Would you like to expand your search
                                            distance?
                                        </p>
                                        <p
                                            class="d-flex justify-content-center align-items-center"
                                        >
                                            <b-button
                                                variant="primary"
                                                @click="expandSearchDistance"
                                            >
                                                Expand Search Distance
                                            </b-button>
                                        </p>
                                    </div>

                                    <div v-else>
                                        <p>
                                            Would you like to clear all filters
                                            to start again?
                                        </p>
                                        <p
                                            class="d-flex justify-content-center align-items-center"
                                        >
                                            <b-button
                                                variant="primary"
                                                @click="clearAllSelectedFilters"
                                            >
                                                {{
                                                    $t(
                                                        "component.facets.clearAll"
                                                    )
                                                }}
                                            </b-button>
                                        </p>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row
                        v-if="!isLoading && !isCollectionEmpty(vehicles)"
                        class="mb-3 no-gutters-sm-down"
                    >
                        <b-col>
                            <b-pagination
                                v-model="page"
                                :total-rows="paginationTotal"
                                :per-page="perPage"
                                align="center"
                                @input="pageChange"
                            />
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="no-gutters-sm-down">
                <b-col>
                    <div class="warranty-disclaimer p-3 px-md-0">
                        <lifetime-warranty-disclaimer />
                    </div>
                </b-col>
            </b-row>
        </b-container>

        <geo-location />

        <sign-in-required-modal />

        <privacy-protection-modal />

        <what-to-look-for-modal>
            <template slot="indicator">
                <linear-indicator
                    v-if="isListViewSelected"
                    class="d-none d-md-flex"
                />
                <grid-indicator
                    v-if="isGridViewSelected"
                    class="d-none d-md-flex"
                />

                <listing-indicator-mobile class="d-flex d-md-none" />
            </template>
            <template slot="checkbox">
                <div class="text-center">
                    <input
                        id="checkbox1"
                        v-model="listingIndicatorChecked"
                        type="checkbox"
                        class="mr-1"
                    />
                    <label for="checkbox1"
                        >Got It. Do not show this listings message again.</label
                    >
                </div>
            </template>
        </what-to-look-for-modal>
    </div>
</template>

<script>
import { get, dispatch, sync, commit } from "vuex-pathify";
import _ from "lodash";
import LoadingSpinner from "../../components/LoadingSpinner";
import SortSelector from "./components/SortSelector";
import GeoLocation from "../../components/GeoLocation";
import SignInRequiredModal from "../../components/SignInRequiredModal";
import PrivacyProtectionModal from "../../components/PrivacyProtectionModal";
import DesktopFilters from "Modules/UsedListings/components/DesktopFilters";
import GridView from "./components/ListingCard/GridView";
import ListingCard from "./components/ListingCard/ListView";
import ViewSelector from "./components/ViewSelector";
import WhatToLookForModal from "Components/WhatToLookForModal/index";
import EventBus from "../../event-bus";
import LinearIndicator from "Components/WhatToLookForModal/components/LinearIndicator";
import GridIndicator from "Components/WhatToLookForModal/components/GridIndicator";
import ListingIndicatorMobile from "Components/WhatToLookForModal/components/ListingIndicatorMobile";
import InventoryMobileNavmenu from "Modules/UsedListings/components/InventoryMobileNavmenu/index";
import DistanceBucketLabel from "Modules/UsedListings/components/DistanceBucketLabel";
import LifetimeWarrantyDisclaimer from "Components/LifetimeWarrantyDisclaimer";


export default {
    name: "UsedListings",

    components: {
        LifetimeWarrantyDisclaimer,
        DistanceBucketLabel,
        InventoryMobileNavmenu,
        WhatToLookForModal,
        DesktopFilters,
        PrivacyProtectionModal,
        SignInRequiredModal,
        GeoLocation,
        SortSelector,
        LoadingSpinner,
        GridView,
        ListingCard,
        ViewSelector,
        LinearIndicator,
        GridIndicator,
        ListingIndicatorMobile
    },

    props: {
        showPaymentTypeFacet: {
            type: Boolean,
            default: true,
            required: false
        },
        showStockTypeFacet: {
            type: Boolean,
            default: false,
            required: false
        },
        showMilesFacet: {
            type: Boolean,
            default: true,
            required: false
        }
    },

    data: function() {
        return {
            isMobile: null,
            recommendedDisclaimer: this.$t("disclaimer.recommended"),
            listingIndicatorChecked: false,
            showListingIndicatorAgain: true,
            previousDistance: null,
            distanceBuckets: [25, 50, 100, 250]
        };
    },

    computed: {
        zipCode: get("location/selectedLocation@zipCode"),
        lang: get("translated/lang"),
        listings: get("used/listings@vehicleDetails"),
        isLoading: get("used/isLoading"),
        isError: get("used/isError"),
        distance: sync("vehicleSearch/filters@distance"),
        sortBy: get("vehicleSearch/pageable@sort"),
        page: sync("vehicleSearch/pageable@page"),
        pageMetadata: get("vehicleSearch/pageMetadata"),
        filtersChanged: get("vehicleSearch/filterCommitCount"),
        vehiclesInRange: get("used/vehiclesInRange"),
        stockTypes: get("vehicleSearch/filters@stockTypes"),
        isListViewSelected: get("vehicleSearch/listViewActive"),
        isGridViewSelected: get("vehicleSearch/gridViewActive"),
        filtersAsUrl: get("vehicleSearch/getFiltersAsUrl"),
        campaignType: get("campaign/type"),
        selectedDealer: get("used/selectedDealer"),
        isDealerType() {
            return !_.isNil(this.selectedDealer);
        },
        isTypeUsed() {
            return this.stockTypes.indexOf("USED") !== -1;
        },
        certifiedDealer() {
            return _.chain(this.vehicles)
                .filter(function(vehicle) {
                    return vehicle.dealer.certified === true;
                })
                .groupBy("distanceBucket")
                .value();
        },
        nonCertifiedDealer() {
            return _.filter(this.vehicles, function(vehicle) {
                return vehicle.dealer.certified === false;
            });
        },
        isTypeNew: function() {
            const type = "NEW";

            if (Array.isArray(this.stockTypes)) {
                return this.stockTypes.indexOf(type) !== -1;
            }

            return false;
        },
        vehicles() {
            return !_.isEmpty(this.listings) ? this.listings : [];
        },
        startNumber() {
            return (
                this.page * this.pageMetadata.size + 1 - this.pageMetadata.size
            );
        },
        endNumber() {
            const endNumber = this.page * this.pageMetadata.size;

            if (endNumber > this.pageMetadata.totalElements) {
                return this.pageMetadata.totalElements;
            }

            return endNumber;
        },
        total() {
            return _.get(this.pageMetadata, "totalElements", 0);
        },
        paginationTotal() {
            // Do not allow showing more than 5,000
            const total = _.get(this.pageMetadata, "totalElements", 0);
            return total > 5000 ? 5000 : total;
        },
        perPage() {
            return _.get(this.pageMetadata, "size", 20);
        },
        showGridView() {
            return this.isGridViewSelected || this.isMobile;
        },
        isDealerInventorySearch() {
            return !_.isNil(this.selectedDealer);
        }
    },

    watch: {
        zipCode(newZipCode, oldZipCode) {
            if (!_.isNil(newZipCode) && newZipCode !== oldZipCode) {
                dispatch("used/handleZipCodeChanged");
            }
        },
        page() {
            dispatch("used/handlePageChange");
        },
        filtersChanged() {
            dispatch("used/handleFiltersChanged");
        },
        listingIndicatorChecked() {
            if (this.listingIndicatorChecked === true) {
                this.showListingIndicatorAgain = false;
                localStorage.setItem("showListingIndicatorAgain", "false");
            } else {
                this.showListingIndicatorAgain = true;
                localStorage.setItem("showListingIndicatorAgain", "true");
            }
        }
    },

    created() {
        dispatch("used/handleLoadInitialListings");

        this.checkIfMobile();

        window.addEventListener("resize", this.checkIfMobile);
    },
    mounted() {
        if (
            this.campaignType === "wavpp" &&
            (window.localStorage.showListingIndicatorAgain === "true" ||
                _.isNil(window.localStorage.showListingIndicatorAgain))
        ) {
            EventBus.$emit("what-to-look-for-modal-open");
        }
    },
    methods: {
        mapToDistance(distance) {
            const currentKey = _.indexOf(
                _.values(this.distanceBuckets),
                _.toNumber(distance)
            );
            if (currentKey === -1) {
                return _.last(this.distanceBuckets);
            }

            const previousKey = currentKey - 1;
            return _.values(this.distanceBuckets)[previousKey];
        },
        distanceFirst(distance) {
            return distance === _.keys(this.certifiedDealer)[0];
        },
        expandSearchDistance() {
            this.distance = -1;
        },
        isCollectionEmpty(collection) {
            return _.isEmpty(collection);
        },

        clearAllSelectedFilters() {
            dispatch("vehicleSearch/clearAllFilters");
        },

        goToTopOfPage() {
            window.scrollTo(0, 0);
        },

        pageChange() {
            this.goToTopOfPage();
        },

        activateGridView() {
            commit("vehicleSearch/SET_GRID_VIEW_ACTIVE", true);
        },

        checkIfMobile() {
            this.isMobile = window.matchMedia(
                "only screen and (max-width: 767px)"
            ).matches;
        },
        showModels() {
            if (this.sortBy.indexOf("distance") !== -1) {
                window.location.href = this.filtersAsUrl(
                    "/shop/models",
                    "savings,desc"
                );
            } else {
                window.location.href = this.filtersAsUrl("/shop/models");
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
    height: calc(100vh - 80px);
}

.listings-view-link {
    color: #888;
}

.models-view-link {
    color: #007dc6;
}

.recommended-listing-title,
.standard-listing-title {
    margin-top: 18px;
}

.recommended-listing-title {
    font-family: $helvetica-46-light-italic;

    strong {
        font-family: $helvetica-65-medium;
        font-weight: normal;
    }
}

.grid-listing,
.listing {
    margin-bottom: 10px;
}
.grid-listing {
    width: calc(50% - 5px);
}

.sort-wrapper {
    flex: 10;
}

.sorting {
    &.view-selection {
        font-size: px2rem(13);
    }
}

.total {
    color: $muted-dark-gray;
    font-size: px2rem(13);
}

.warranty-disclaimer {
    font-size: px2rem(12);
    line-height: 15px;
    font-style: italic;
    color: $muted-dark-gray;
}

@include media-breakpoint-down(xs) {
    .grid-listing {
        width: 100%;
    }
}

.sorting {
    .inactive-view {
        color: #888;
    }
    .active-view {
        color: $primary-blue;
        font-family: $helvetica-75-bold;
    }
}

.warranty-disclaimer {
    font-size: px2rem(12);
    line-height: 15px;
    font-style: italic;
    color: $muted-dark-gray;
}
</style>
