var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vehicle-location text-right" }, [
    _vm.vehicle.dealer.deliveryOnly
      ? _c("span", [
          _vm._v("Delivers to\n        "),
          _c("span", { staticClass: "text-nowrap" }, [
            _vm._v(
              _vm._s(_vm.location.city) + ", " + _vm._s(_vm.location.state)
            )
          ])
        ])
      : _c("span", [
          _vm._v("Near\n        "),
          _c("span", { staticClass: "text-nowrap" }, [
            _vm._v(
              _vm._s(_vm.vehicle.dealer.city) +
                ", " +
                _vm._s(_vm.vehicle.dealer.stateCode)
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }