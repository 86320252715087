var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    { staticClass: "mx-0 my-2 p-0", attrs: { id: "gridListingIndicator" } },
    [
      _c("div", { staticClass: "warranty-indicator" }, [
        _c("span", [
          _vm._v(
            "Indicates that vehicle comes with CarSaver’s Lifetime Warranty.\n            (included at no cost you)"
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "certified-dealer-indicator" }, [
        _c("span", [
          _vm._v("Indicates that vehicle is located at a\n            "),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v("CarSaver Certified Dealership")
          ]),
          _vm._v("\n            and comes with additional benefits.")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "deal-rating-indicator" }, [
        _c("span", [
          _vm._v("The\n            "),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v("estimated deal rating")
          ]),
          _vm._v(
            "\n            indicates the price of this vehicle relative to similar vehicles\n            in your area."
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }